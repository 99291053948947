import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import labelStyles from '../../styles/label'
import bodyMediumPlusStyles from '../../styles/bodyMediumPlus'

const Wrapper = styled.div`
  ${bodyMediumPlusStyles}
  margin: 0 auto 2.5rem auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;

  &:not(:last-child):after {
    content: '';
    height: 1px;
    background: ${props => props.theme.colors.primary};
    width: 74%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Day = styled.div`
  ${labelStyles}
  ${props => props.theme.fonts.sansSerif}
  padding: 0.25rem 0 0.25rem 0;
`

const Time = styled.div``

const Hours = () => (
  <Wrapper>
    <Items>
      <Item>
        <Day>
          <FormattedMessage id="contact.hours.monday-thursday" />
        </Day>
        <Time>08:00 – 18:00</Time>
      </Item>
      <Item>
        <Day>
          <FormattedMessage id="contact.hours.friday" />
        </Day>
        <Time>08:00 – 17:00</Time>
      </Item>
    </Items>
  </Wrapper>
)

export default Hours
