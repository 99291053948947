import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Offices from '../components/Contact/Offices'
// import Form from '../components/Contact/Form'
import Hours from '../components/Contact/Hours'

const ContactPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'contact.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'contact.title' })}>
          <FormattedMessage id="contact.headline" />
        </Title>
        <Offices />
        <Hours />
        {/* Form wird bis auf weiteres deaktiviert. <Form /> */}
      </Content>
    </Layout>
  )
}

export default ContactPage

export const i18n = {
  en: '/contact',
  de: '/kontakt',
}
